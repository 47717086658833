import {getWithAuthDecorator, hasWindow} from '@services/http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import * as CsrHttpService from '@http/csr-http.service';
import {
    GET_EXISTING_ACCOUNT_ADMIN_URL,
    GET_EXISTING_ACCOUNT_MULTIPLE_DATA,
    GET_EXISTING_ACCOUNT_URL,
    GET_EXISTING_PAN_URL,
    GET_USER_DATA_URL,
    GET_USERS_URL,
    POST_LOGOUT_USER_URL,
    PUT_DEACTIVATE_USER_URL,
    POST_DEACTIVATE_INDIVIDUAL_INVESTOR_URL,
    GET_USER_LEVEL_NUDGES,
    POST_VERIFY_USER_EMAIL,
    POST_UPDATE_USER_EMAIL,
    GET_USER_URL,
    GET_GEN_AI_ACCESS_BY_USER_ID,
} from '@services/urls/user';

export const validate = (mobile) => {
    const url = GET_EXISTING_ACCOUNT_URL(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url)
    }
    return SsrHttpService.getWithOutAuth(url);
};

// As this Endpoint does not have a session, but invokes a secure endpoint
// Have changed the implementation a little.
export const getMultipleData = (mobile, auth) => {
    const url = GET_EXISTING_ACCOUNT_MULTIPLE_DATA(mobile);
    return getWithAuthDecorator(url, auth)
}

export const getExistingAccountsOfMobileNo = (mobile) => {
    const url = GET_EXISTING_ACCOUNT_ADMIN_URL(mobile);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url)
    }
    return SsrHttpService.getWithAuth(url);
};

export const validatePan = (pan) => {
    const url = GET_EXISTING_PAN_URL(pan);
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url)
    }
    return SsrHttpService.getWithOutAuth(url);
};

export const getUsers = (page, limit, searchText, ctx = null) => {
    const url = GET_USERS_URL(page, limit, searchText);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url)
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getUserData = (id, ctx = null) => {
    const url = GET_USER_DATA_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url)
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const logoutUser = (data, ctx = null) => {
    const url = POST_LOGOUT_USER_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(url, data)
    }
    return SsrHttpService.postWithOutAuth(url, data, ctx)
}

export const deactivateUserAccount = (userId, entity = {}, ctx = null) => {
    const url = PUT_DEACTIVATE_USER_URL(userId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, entity)
    }
    return SsrHttpService.putWithAuth(url, entity, ctx);
}

export const deactivateIndividualUserAccount = (userId, entity = {}, ctx = null) => {
    const url = POST_DEACTIVATE_INDIVIDUAL_INVESTOR_URL(userId);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, entity)
    }
    return SsrHttpService.postWithAuth(url, entity, ctx);
}

export const getUserLevelNudges = (userId, ctx = null) => {
    const url = GET_USER_LEVEL_NUDGES(userId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

// data = {mobile, otpId}
export const verifyUserEmail = (data, ctx = null) => {
    const url = POST_VERIFY_USER_EMAIL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

// data = {mobile, otpIdList, email}
export const updateUserEmail = (data, ctx = null) => {
    const url = POST_UPDATE_USER_EMAIL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
};

export const getUserById = (id, ctx = null) => {
    const url = GET_USER_URL(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getGenAiAccessByUserId = (userId, ctx = null) => {
    const url = GET_GEN_AI_ACCESS_BY_USER_ID(userId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}