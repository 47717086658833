/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
//import '@ui/styles/globals.css';
import { getLayoutConfig } from '@components/Layout/LayoutConfiguration';
import { AuthProvider } from '@context/auth';
import { notInvestorPaths } from '@helpers/constants.js';
import useAuthStore from '@store/useAuthStore.js';
import '@styles/Homepage/style.css';
import '@styles/globals.css';
import 'aos/dist/aos.css';
import Aos from 'aos';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router.js';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from '@services/identity.service';

const ToastContainer = dynamic(() =>
    import('react-toastify').then((mod) => mod.ToastContainer)
);
const GenAIChat = dynamic(() => import('@components/GenAi'), {
    ssr: false,
});

const WithAuth = dynamic(() => import('@components/Auth/WithAuth'), {
    ssr: true,
    csr: true,
});

function MyApp({ Component, pageProps, router }) {
    let layoutConfig = getLayoutConfig(router.pathname);
    const { Layout: FetchedLayout, overrides } = layoutConfig;
    const { initialize, canUseAiBot } = useAuthStore();
    const nextRouter = useRouter();
    const auth = getAuth();
    const isError = pageProps?.statusCode && pageProps.statusCode >= 400;

    useEffect(() => {
        // Initialize animations and other configurations if the current path is not part of the excluded paths
        if (!notInvestorPaths.includes(layoutConfig?.path)) {
            initialize(nextRouter);
        }
        
        // Initialize AOS (Animate on Scroll) with custom settings
        Aos.init({ 
            duration: 800, 
            once: false 
        });
  

        // Disable the automatic scroll restoration behavior provided by the browser
        // This prevents the browser from automatically scrolling to the previous position
        // when navigating back to the previous page.
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }
    }, []);

    return (
        <>
            <WithAuth router={router} isError={isError}>
                {notInvestorPaths.includes(layoutConfig?.path) ? (
                    <FetchedLayout
                        children={<Component {...pageProps} />}
                        {...overrides}
                    />
                ) : (
                    <AuthProvider>
                        <FetchedLayout
                                children={<Component {...pageProps} />}
                                {...overrides}
                        />
                        {auth && canUseAiBot ? <GenAIChat /> : null}
                    </AuthProvider>
                )}
            </WithAuth>
            <ToastContainer />
        </>
    );
}

export default MyApp;
