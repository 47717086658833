import axios from 'axios';
import router from 'next/router';
import { REFRESH_SESSION } from "@services/urls/session";
import { setAuth } from './identity.service';

/*
* Assume this is an abstract class not to be used for making http
* calls. Use the clients - CSR and SSR http service as it handles
* auth, refreshing of tokens by default.
*
* */

export const postWithOutAuthDecorator = (url, entity) => {
    try {
        return new Promise((resolve, reject) => {
            axios
                .post(url, entity, { withCredentials: true })
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    }
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    } catch (error) {
        console.error({ url, error: JSON.stringify(error) });
    }
};

export const getWithOutAuthDecorator = (url) => {
    try {
        return new Promise((resolve, reject) => {
            axios
                .get(url, { withCredentials: true })
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    }
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    } catch (error) {
        console.error({ url, error: JSON.stringify(error) });
    }
};

export const postWithAuthDecorator = async (url, entity, auth) => {
    try {
        const request = async (auth) => {
            const headers = {
                'content-type': 'application/json',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.post(url, entity, {
                headers,
                withCredentials: true
            });
        };
        return handleRequest(request, auth);
    } catch (e) {
        console.error({ url, entity, error: JSON.stringify(e) });
    }
};

export const postWithAuthAndHeadersDecorator = async (url, entity, auth) => {
    try {
        const request = async (auth) => {
            const headers = {
                'content-type': 'application/json',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.post(url, entity, {
                headers,
                withCredentials: true
            });
        };
        return handleRequestWithHeaders(request, auth);
    } catch (e) {
        console.error({ url, entity, error: JSON.stringify(e) });
    }
};

export const postWithAuthDecoratorMultipart = async (url, entity, auth) => {
    try {
        const request = async (auth) => {
            const headers = {
                'content-type': 'multipart/form-data',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.post(url, entity, {
                headers,
                withCredentials: true
            });
        };
        return handleRequest(request, auth);
    } catch (e) {
        console.error({ url, entity, error: JSON.stringify(e) });
    }
};

const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};

export const getWithAuthDecorator = async (url, auth) => {
    try {
        const request = (auth) => {
            const headers = {
                'content-type': 'application/json',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.get(url, {
                headers,
                withCredentials: true
            });
        };
        return handleRequest(request, auth);
    } catch (e) {
        console.error({ url, error: JSON.stringify(e) });
    }
};

export const deleteWithAuthDecorator = async (url, entity, auth) => {
    try {
        const request = (auth) => {
            const headers = {
                'content-type': 'application/json',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.delete(url, {
                headers,
                withCredentials: true,
                data: entity
            });
        };
        return handleRequest(request, auth);
    } catch (e) {
        console.error({ url, entity, error: JSON.stringify(e) });
    }
};

export const putWithAuthDecorator = async (url, entity, auth) => {
    try {
        const request = (auth) => {
            const headers = {
                'content-type': 'application/json',
                ...(auth ? {
                    'x-access-token': auth.token,
                    'authorization': "Bearer " + auth.token,
                } : {}),
            };
            return axios.put(url, entity, {
                headers,
                withCredentials: true
            });
        };
        return handleRequest(request, auth);
    } catch (e) {
        console.error({ url, entity, error: JSON.stringify(e) });
    }
};

export const refreshToken = (auth) => {
    try {
        return new Promise((resolve, reject) => {
            axios
                .post(REFRESH_SESSION(), auth, { withCredentials: true })
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    }
                })
                .catch((ex) => {
                    const status = ex.status;
                    if (status === 401 || status === 403) reject(ex);
                    else {
                        reject(ex);
                    }
                });
        });
    } catch (error) {
        console.error(REFRESH_SESSION(), { error: JSON.stringify(error) });
    }
};

export const getUserIp = async () => {
    const url = 'https://api.ipify.org?format=json';
    try {
        const response = await axios.get(url, { withCredentials: true });
        return response?.data?.ip;
    } catch (error) {
        console.error(error);
    }
};

const onUnauthorizedRequest = () => {
    if (hasWindow()) {
        return router.push('/logout');
    } else {
        throw new Error("ForcedLogoutError");
    }
};

export const hasWindow = () => {
    return typeof window === 'object';
};

const handleRequest = async (request, auth) => {
    try {
        let response = await request(auth);
        return response.data;
    } catch (e) {
        return handleHttpError(e, auth, request);
    }
};

const handleRequestWithHeaders = async (request, auth) => {
    try {
        let response = await request(auth);
        return response;
    } catch (e) {
        return handleHttpError(e, auth, request);
    }
};

const handleHttpError = async (ex, auth, request) => {
    const status = ex.response?.status;
    if (status === 401) {
        let refreshResult = await refreshToken(auth);
        if (refreshResult && refreshResult.status) {
            setAuth(refreshResult.entity);
            let response = await request(refreshResult.entity);
            return { ...response.data, token: refreshResult.entity };
        } else {
            return onUnauthorizedRequest();
        }
    } else {
        throw ex;
    }
};

export const putWithOutAuthDecorator = (url, entity) => {
    try {
        return new Promise((resolve, reject) => {
            axios
                .put(url, entity, { withCredentials: true })
                .then((response) => {
                    if (response && response.data) {
                        resolve(response.data);
                    }
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    } catch (error) {
        console.error({ url, error: JSON.stringify(error) });
    }
};