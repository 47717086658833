import { GenAiServiceUrl, UrlParamsReplace } from "@services/urls/index";

export const GET_GEN_AI_STREAMING_URL = (query) => GenAiServiceUrl(`/convo/query`);

export const GET_GEN_AI_GENERATED_QUESTIONS = () => GenAiServiceUrl('/genai/generate-questions');

export const CREATE_GEN_AI_CONSENT_URL = () => UrlParamsReplace('/ai-consent');

export const GET_GEN_AI_CONSENT = (entityId) => UrlParamsReplace('/ai-consent/:entityId', { entityId });

export const UPDATE_GEN_AI_CONSENT = (userId) => UrlParamsReplace('/ai-consent/:userId', { userId });

export const LOGOUT_GENAI_URL = () => GenAiServiceUrl('/app/logout');

export const FEEDBACK_URL = () => GenAiServiceUrl('/chat-feedbacks');

export const GENAI_FILE_UPLOAD_URL = () => GenAiServiceUrl('/rag-store/file-upload');

export const GET_GENAI_DOCUMENTS_BY_ISIN = (isin) => GenAiServiceUrl(`/rag-store?isin=${isin}`);
