const OpportunityStateEnum = Object.freeze({
    PREPAID_AND_CLOSED: 'PrepaidAndClosed',
    MATURED_AND_CLOSED: 'MaturedAndClosed',
    INVESTMENT_READY: 'InvestmentReady',
    ONGOING_REPAYMENT: 'OngoingRepayment',
    IN_DRAFT: 'InDraft',
    // TODO: REFACTOR_CODE spelling fix
    SUBSCRIPTION_READY: 'SubcriptionReady'
});

const OpportunityTypes = Object.freeze({
    TREASURY_BILL: 'TreasuryBill',
    SOVEREIGN_GOLD_BONDS: 'SovereignGoldBond',
    G_SEC: 'GSec',
    SDL: 'SDL',
    SECURITISED_DEBT_INSTRUMENT: 'SecuritisedDebtInstrument',
    UNLISTED_SECURITISED_DEBT_INSTRUMENT: 'UnlistedSecuritisedDebtInstrument',
    CORPORATE_BOND: 'CorporateBond'
});

const OpportunityTypeNonSdiDisplayNames = Object.freeze({
    TreasuryBill: 'Treasury Bill',
    SovereignGoldBond: 'Sovereign Gold Bond',
    GSec: 'Government Security',
    SDL: 'State Development Loan',
    CorporateBond: 'Corporate Bond'
});

const OpportunityTypeDisplayNames = Object.freeze({
    ...OpportunityTypeNonSdiDisplayNames,
    SecuritisedDebtInstrument: 'Securitised Debt Instrument (SEBI Regulated)',
    UnlistedSecuritisedDebtInstrument: 'Securitised Debt Instrument (RBI Regulated)',
});

const OpportunityTypeDisplayNamesPieChart = Object.freeze({
    ...OpportunityTypeNonSdiDisplayNames,
    SecuritisedDebtInstrument: 'Securitised Debt Instrument (SEBI)',
    UnlistedSecuritisedDebtInstrument: 'Securitised Debt Instrument (RBI)',
});

const OpportunityTagType = Object.freeze({
    HIGH_YIELD: 'High Yield',
    NEWLY_ADDED: 'Newly Added',
    LOW_MIN_INVESTMENT: 'Low Min Investment',
    SHORT_TENURE: 'Short Tenure',
    DEFAULT: 'Curated',
    COMING_SOON: 'Coming Soon',
    FESTIVE_DEAL: 'Festive Special',
});

const OpportunityTag = Object.freeze({
    HIGH_YIELD: 'highYield',
    NEWLY_ADDED: 'newlyAdded',
    LOW_MIN_INVESTMENT: 'lowMinInvestment',
    SHORT_TENURE: 'shortTenure',
    COMING_SOON: 'comingSoon',
    FESTIVE_DEAL: 'festiveDeal',
    DEFAULT: 'popular',
});

const OpportunityCarouselTags =  Object.freeze({
    LIVE_NOW: 'Live now',
    FULLY_SUBSCRIBED: 'Fully subscribed',
});


export { OpportunityStateEnum, OpportunityTypes, OpportunityTypeDisplayNames, OpportunityTypeDisplayNamesPieChart,OpportunityTagType,OpportunityTag, OpportunityCarouselTags };
