const KYC_BASE_URL = '/kyc/verification';
const OPPORTUNITIES = '/opportunities';
const LOGGED_OUT_OPPORTUNITIES = '/active/opportunities';
const BOND_DIRECTORY = '/bond-directory';
const BONDS = '/bonds'
export {
    KYC_BASE_URL,
    OPPORTUNITIES,
    LOGGED_OUT_OPPORTUNITIES,
    BOND_DIRECTORY,
    BONDS
};
