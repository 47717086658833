export const INVESTMENT_TABS = {
    ACTIVE: 'active',
    MATURED: 'matured',
    PENDING: 'pending',
    FAILED: 'failed',
};

export const INVESTMENT_STATUS = {
    ACTIVE: 'active',
    FULLY_REPAID: 'fullyRepaid',
};

export const INVESTMENT_ORDER_STATUS = {
    IN_PROGRESS: 'inProgress',
    FAILED: 'failed',
};

export const TABS_CACHE_KEY = 'investmentTabsCount';

export const TABS_COUNT_EXPIRY = 1000 * 60 * 60 * 2; //  2 hours

export const LIMIT = 10;

export const PENDING_PAGINATION_LIMIT = 10;

export const PENDING_PAGE_SIZE = 10;
