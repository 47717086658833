import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useEffect } from 'react';
import { getGenAiConsent, getPregeneratedQuestions, resetStore, sendMessage, setVisibility, storeGenAiConsent, toggleVisibility, updateLike, GEN_AI_ROLES, MESSAGE_LIMIT, INITIAL_STATE } from '@store/actions/aiChatBotActions';
import { showGenAIPreGeneratedQuestions } from '@helpers/constants';

/*
  Initial state for the AI ChatBot store
  ---------------------------------------
  - isVisible: Controls the visibility of the chatbot
  - messages: Stores user-bot conversation history
  - streaming: Indicates if a message is currently being streamed
  - isLoading: Tracks the loading state of the chatbot
  - defaultOptions: Stores pre-generated questions for quick replies
  - fetchingOptions: Indicates if pre-generated questions are being fetched
  - defaultOptionsVisible: Controls visibility of pre-generated questions
  - isConsentGiven: Stores the GenAI consent status (true | false | null)
  - displayMessageCount: Defines how many messages should be displayed at a time
*/

const useAIChatBotStore = create(
    persist(
        (set, get) => ({
            ...INITIAL_STATE,
            toggleVisibility: toggleVisibility(set, get),
            setVisibility: setVisibility(set),
            getPregeneratedQuestions: getPregeneratedQuestions(set),
            updateLike: updateLike(set),
            sendMessage: sendMessage(set),
            setStreaming: (value) => {
                set((state) => ({ streaming: value }));
            },
            storeGenAiConsent: storeGenAiConsent(set),
            getGenAiConsent: getGenAiConsent(set),
            resetStore: resetStore(set),
            loadOlderMessages: () => {
              set((state) => {
                if (state.displayMessageCount >= state.messages.length) return {};
                return { displayMessageCount: state.displayMessageCount + MESSAGE_LIMIT };
              });
            },
            getVisibleMessages: () => {
              const { messages, displayMessageCount } = get();
              return messages.slice(-displayMessageCount);
            },      
            resetLoadingMessages: () => { // Reset any bot messages that were left in a loading state
                set((state) => ({ 
                  messages: state.messages.map((msg) => msg.role === GEN_AI_ROLES.BOT && msg.loading ? { ...msg, loading: false, content: 'Loading was interrupted.' } : msg),
                  isLoading: false
                }));
            },
        }),
        {
            name: 'ai-chat-bot-storage',
            // To keep these states in storage
            partialize: (state) => ({
                defaultOptions: state.defaultOptions,
                messages: state.messages,
                defaultOptionsVisible: state.defaultOptionsVisible,
                isConsentGiven: state.isConsentGiven
            }),
        }
    )
);

// pregenerated questions hook
export const useInitializeDefaultOptions = () => {
  const { resetLoadingMessages, defaultOptions, defaultOptionsVisible, getPregeneratedQuestions, fetchingOptions } = useAIChatBotStore((state) => ({
       defaultOptions: state.defaultOptions,
       defaultOptionsVisible: state.defaultOptionsVisible,
       getPregeneratedQuestions: state.getPregeneratedQuestions,
       resetLoadingMessages: state.resetLoadingMessages,
       fetchingOptions: state.fetchingOptions,
  }));

  useEffect(() => {
    // Reset any bot messages that were left in a loading state
    resetLoadingMessages();

    if (defaultOptionsVisible && !defaultOptions?.length && !fetchingOptions && showGenAIPreGeneratedQuestions) {
      getPregeneratedQuestions();
    }
  }, []);
};


export default useAIChatBotStore;