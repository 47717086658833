import {INVESTMENT_TABS} from '@components/Investments/utils/constants';
import {BOND_DIRECTORY, BONDS, KYC_BASE_URL, LOGGED_OUT_OPPORTUNITIES, OPPORTUNITIES} from './base-urls';
import {createUrl} from './helper';

const routes = {
    kyc: {
        kyc: createUrl(`/kyc`),
        basicDetails: {
            base: createUrl(`${KYC_BASE_URL}/basic-details`),
            residential: createUrl(`${KYC_BASE_URL}/basic-details`, {
                residential: true,
            }),
        },
        idVerification: {
            base: createUrl(`${KYC_BASE_URL}/id-verification`),
            pan: createUrl(`${KYC_BASE_URL}/id-verification`, {pan: true}),
            address: createUrl(`${KYC_BASE_URL}/id-verification`, {
                address: true,
            }),
        },
        additionalDetails: {
            base: createUrl(`${KYC_BASE_URL}/personal-details`),
            personal: createUrl(`${KYC_BASE_URL}/personal-details`, {
                personal: true,
            }),
            nominee: createUrl(`${KYC_BASE_URL}/personal-details`, {
                nominee: true,
            }),
        },
        bankDematDetails: {
            base: createUrl(`${KYC_BASE_URL}/bank-demat-details`),
            bank: createUrl(`${KYC_BASE_URL}/bank-demat-details`, {
                bank: true,
            }),
            demat: createUrl(`${KYC_BASE_URL}/bank-demat-details`, {
                demat: true,
            }),
        },
        aadhaar: {
            base: createUrl(`${KYC_BASE_URL}/aadhaar-esign`),
            liveliness: createUrl(`${KYC_BASE_URL}/aadhaar-esign`, {
                liveliness: true,
            }),
            signature: createUrl(`${KYC_BASE_URL}/aadhaar-esign`, {
                signature: true,
            }),
            esign: createUrl(`${KYC_BASE_URL}/aadhaar-esign`, {esign: true}),
        },
        kycStatus: createUrl(`${KYC_BASE_URL}/kyc-status`),
    },
    opportunities: createUrl(`${OPPORTUNITIES}`),
    loggedOutOpportunities: createUrl(`${LOGGED_OUT_OPPORTUNITIES}`),
    bonds: {
        base: createUrl(`${BOND_DIRECTORY}`),
        baseIsin: createUrl(`${BONDS}/isin/[isin]`),
        byIsin: (isin) => createUrl(`${BONDS}/isin/${isin}`),
        bondsIsin: createUrl(`${BONDS}/isin`),
    },
    welcome: {
        base: createUrl('/welcome'),
        isReturning: createUrl('/welcome', {isReturning: true}),
        isNotReturning: createUrl('/welcome', {isReturning: false}),
    },
    questionnaire: {
        base: createUrl('/questionnaire'),
    },
    dashboard: {
        base: createUrl('/dashboard'),
        investments: {
            base: createUrl('/dashboard/investments'),
            active: createUrl('/dashboard/investments', {
                status: INVESTMENT_TABS.ACTIVE,
            }),
            matured: createUrl('/dashboard/investments', {
                status: INVESTMENT_TABS.MATURED,
            }),
            pending: createUrl('/dashboard/investments', {
                status: INVESTMENT_TABS.PENDING,
            }),
            failed: createUrl('/dashboard/investments', {
                status: INVESTMENT_TABS.FAILED,
            }),
        },
        myReferrals: createUrl('/dashboard/my-referrals'),
    },
    referAndEarn: {
        base: createUrl('/refer-and-earn'),
    },
};

export default routes;
