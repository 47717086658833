import { createGenAiConsent, getGenAiConsentByEntityId, getGenAiGeneratedQuestions, getGenAiStreamingMessages, postGenAiFeedback } from '@services/genAi-sevice';
import { v4 as uuidv4 } from 'uuid';
import { isValidResponseEntity } from '@helpers/utils';

export const GEN_AI_ROLES = { // Messages having roles
  USER: "user",
  BOT: "bot"
}

// DOM size limitations, pagination size: 10 messages 
export const MESSAGE_LIMIT = 10;

export const INITIAL_STATE = {
  isVisible: false, // GenAi visibility
  messages: [], // messages [User, Bot]
  streaming: false, // streaming a message
  isLoading: false,
  defaultOptions: [], // pregenerated questions
  fetchingOptions: false, // pregenerated questions loading state
  defaultOptionsVisible: true, // pregenerated visibility
  isConsentGiven: null, // genAi consent present/not : (true | false | null)
  displayMessageCount: MESSAGE_LIMIT,
}

const isValidResponse = (response) => response && response.data;

export const toggleVisibility = (set, get) => async () => {
  const currentState = get();
  const wasVisible = currentState.isVisible;
  set({ isVisible: !wasVisible });
};

export const setVisibility = (set) => (visible) => set(() => ({ isVisible: visible }));

export const getPregeneratedQuestions = (set) => async () => {
  try {
    set(() => ({ fetchingOptions: true }));
    const fetchedOptions = await getGenAiGeneratedQuestions();
    if(isValidResponse(fetchedOptions)) set(() => ({ defaultOptions: fetchedOptions?.data || [], fetchingOptions: false })); 
  } catch (error) { set(() => ({ defaultOptions: [], fetchingOptions: false, defaultOptionsVisible: false })); console.log(error); }
};

export const updateLike = (set) => async (id, isLiked, requestId) => {
  try {
    const response = await postGenAiFeedback({
      trace_id: requestId,
      is_liked: isLiked,
    });
    if(isValidResponse(response)) {
      set((state) => {
        const updatedMessage = state.messages.map((msg, key) => ({
          ...msg,
          isLiked: msg.id === id ? isLiked : (msg.isLiked || null),
        }));
        return { messages: updatedMessage };
      }); 
    }
  } catch (error) {
    console.log(error.message)
  }
};

export const sendMessage = (set) => async (content) => {
  set((state) => {
    return {
      messages: [
        ...state.messages,
        { role: GEN_AI_ROLES.USER, content, id: uuidv4(), shouldAnimate: state.messages.length === 0 },
        { role: GEN_AI_ROLES.BOT, loading: true, content: '', id: uuidv4() },
      ],
      defaultOptionsVisible: false,
      isLoading: true
    };
  });

  try {
    const response = await getGenAiStreamingMessages({ user_query: content });
    const requestId = response?.headers?.['x-request-id'] || response?.headers?.get('x-request-id');

    set((state) => {
      const updatedMessages = [...state.messages];
      updatedMessages[updatedMessages.length - 1] = {
        ...updatedMessages[updatedMessages.length - 1],
        role: GEN_AI_ROLES.BOT,
        content: response?.data || 'It looks like you might have typed randomly, let me know if you need any help! 😊',
        loading: false,
        requestId: requestId || null
      };
      return { messages: updatedMessages, streaming: response?.data ? true : false };
    });
  } catch (error) {
    set((state) => {
      const updatedMessages = [...state.messages];
      updatedMessages[updatedMessages.length - 1] = {
        ...updatedMessages[updatedMessages.length - 1],
        role: GEN_AI_ROLES.BOT,
        content: 'Some error occurred while generating the response. Please try again later.',
        loading: false,
      };
      return { messages: updatedMessages };
    });
  } finally {
    set((state) => ({ isLoading: false }));

    // make shouldAnimate of first message to false
    setTimeout(() => {
      set((state) => {
        const updatedMessages = [...state.messages];
        updatedMessages[0].shouldAnimate = false;
        return { messages: updatedMessages };
      });
    }, 2000);
  }
};

export const resetStore = (set) => () => {
  set((state) => ({ ...INITIAL_STATE }));
}

export const storeGenAiConsent = (set) => async (payload) => {
  try {
     const response = await createGenAiConsent(payload);
     if(isValidResponseEntity(response)){
          set(() => ({ isConsentGiven: true }));
          return true;
     }
     return false;
  } catch (error) {
      set(() => ({ isConsentGiven: false }));
      return false;
  }
}

export const getGenAiConsent = (set) => async (entityId) => {
  try {
     const response = await getGenAiConsentByEntityId(entityId);
     if(isValidResponseEntity(response)){
       set(() => ({ isConsentGiven: true }));
       return true;
     }

     set(() => ({ isConsentGiven: false }));
     return false
  } catch (error) {
      set(() => ({ isConsentGiven: false }));
      return false
  }
}
