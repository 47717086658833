import * as CsrHttpService from '@http/csr-http.service';
import { hasWindow } from '@services/http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import { CREATE_GEN_AI_CONSENT_URL, GET_GEN_AI_CONSENT, GET_GEN_AI_GENERATED_QUESTIONS, GET_GEN_AI_STREAMING_URL, UPDATE_GEN_AI_CONSENT, LOGOUT_GENAI_URL, GET_GENAI_DOCUMENTS_BY_ISIN, FEEDBACK_URL } from './urls/genAi';

export const getGenAiStreamingMessages = (body, ctx = null) => {
    const url = GET_GEN_AI_STREAMING_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuthAndHeaders(url, body);
    }
    return SsrHttpService.postWithAuthAndHeaders(url, ctx, body);
};

export const getGenAiGeneratedQuestions = (ctx = null) => {
    const url = GET_GEN_AI_GENERATED_QUESTIONS();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createGenAiConsent = (data, ctx) => {
    const url = CREATE_GEN_AI_CONSENT_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, data);
    }
    return SsrHttpService.postWithAuth(url, data, ctx);
}

export const getGenAiConsentByEntityId = (id, ctx) => {
    const url = GET_GEN_AI_CONSENT(id);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
}

export const updateGenAiConsentByUserId = (userId, data, ctx) => {
    const url = UPDATE_GEN_AI_CONSENT(userId);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
}

export const logoutGenAi = (ctx = null) => {
    const url = LOGOUT_GENAI_URL();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url);
    }
    return SsrHttpService.postWithAuth(url, ctx);
};

export const postGenAiFeedback = (data, ctx) => {
    const url = FEEDBACK_URL();
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
}

export const getGenAiDocumentsByIsin = (isin, ctx = null) => {
    const url = GET_GENAI_DOCUMENTS_BY_ISIN(isin);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
