import * as HttpService from "@services/http.service"
import { envsEnum } from "helpers/env-enum"
export const postWithOutAuth = (url, entity) => {
    return HttpService.postWithOutAuthDecorator(url, entity);
};

export const getWithOutAuth = (url) => {
    return HttpService.getWithOutAuthDecorator(url);
};

export const postWithAuth = async (url, entity, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.postWithAuthDecorator(
        url,
        entity,
        getAuthCtx(ctx)
    );
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const postWithAuthAndHeaders = async (url, entity, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.postWithAuthAndHeadersDecorator(
        url,
        entity,
        getAuthCtx(ctx)
    );
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const postWithAuthMultipart = async (url, entity, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.postWithAuthDecoratorMultipart(
        url,
        entity,
        getAuthCtx(ctx)
    );
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const getWithAuth = async (url, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.getWithAuthDecorator(url, getAuthCtx(ctx));
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const deleteWithAuth = async (url, entity, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.deleteWithAuthDecorator(
        url,
        getAuthCtx(ctx)
    );
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const putWithAuth = async (url, entity, ctx) => {
    if (!ctx) {
        console.error(
            'For Server side calls, Please pass the context object.',
            url
        );
    }
    let response = await HttpService.putWithAuthDecorator(url, getAuthCtx(ctx));
    if (response?.token) {
        setCookies(ctx, response);
    }
    return response;
};

export const setCookies = (ctx, response) => {
    const options = [
        'HttpOnly',       // Prevent JavaScript access
        'Path=/',         // Make the cookie accessible across the site
    ];

    if (process.env.NEXT_PUBLIC_CURRENT_ENV !== envsEnum.DEVELOPMENT) {
        options.push('Secure');           // Ensure cookies are sent only over HTTPS
        options.push('SameSite=None');     // Enable cross-origin requests
    }

    ctx.res.setHeader(
        'Set-Cookie',
        `AUTH=${JSON.stringify(response.token)}; ${options.join('; ')}`
    );

    // Update cookies in the request object
    ctx.req.cookies.AUTH = JSON.stringify(response.token);
};

export const getAuthCtx = (ctx) => {
    return JSON.parse(ctx.req.cookies.AUTH);
}

export const putWithOutAuth = (url, entity) => {
    return HttpService.putWithOutAuthDecorator(url, entity)
}